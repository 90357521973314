<template>
  <el-dialog
    v-bind="{
      title: this.editData ? '编辑质检单模板' : '新增质检单模板',
      width: '1024px'
    }"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-form
      ref="form"
      :model="submitForm"
      :rules="rules"
      :show-message="false"
      inline
      label-width="90px"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="模板名称" prop="alloName">
            <el-input v-model="submitForm.alloName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="样品名称" prop="sampName">
            <el-input v-model="submitForm.sampName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="型号规格" prop="model">
            <el-input v-model="submitForm.model"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="模板类型" prop="type">
            <el-select class="w-180" v-model="submitForm.type">
              <el-option v-for="item in configTypes" :value="item.id" :label="item.name" :key="item.id" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="品种" prop="varieties">
            <el-input v-model="submitForm.varieties"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button type="primary" @click="addRow" >新增一行</el-button>
      <el-table :data="submitForm.alloClauList" border class="mt-15">
        <el-table-column label="样品名称" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field1Name"></el-input>
          </template> -->
          <template v-slot>
            <el-input v-model="submitForm.sampName" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column label="型号规格" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field2Name"></el-input>
          </template> -->
          <template v-slot>
            <el-input v-model="submitForm.model" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column label="类型" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field2Name"></el-input>
          </template> -->
          <template v-slot>
            <el-input :value="typeName" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column label="品种" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field2Name"></el-input>
          </template> -->
          <template v-slot>
            <el-input v-model="submitForm.varieties" disabled></el-input>
          </template>
        </el-table-column>
        <el-table-column label="检测项目" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field3Name"></el-input>
          </template> -->
          <template v-slot="{row, $index}">
            <el-form-item :prop="'alloClauList.' + $index + '.testItem'" :rules="rules.title">
              <el-input v-model="row.testItem"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="单位" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field4Name"></el-input>
          </template> -->
          <template v-slot="{row, $index}">
            <el-form-item :prop="'alloClauList.' + $index + '.measUnit'" :rules="rules.title">
              <el-input v-model="row.measUnit"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="技术要求" >
          <!-- <template v-slot:header>
            <el-input v-model="submitForm.fields.field5Name"></el-input>
          </template> -->
          <template v-slot="{row, $index}">
            <el-form-item :prop="'alloClauList.' + $index + '.techRequ'" :rules="rules.title">
              <el-input v-model="row.techRequ"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" >
          <template v-slot="{$index}">
            <el-button type="text">
              <span class="red" @click="deleteRow($index)">删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="visibleDialog=false">取 消</el-button>
      <el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  addOrUpdateQumaAllo,
  qumaAlloDetail
} from '@/apis/quality/qualityTestConfig'
import { configTypes } from '../util'
export default {
  props: {
    visible: { type: Boolean, default: false },
    editData: { type: Object, default: () => {} }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    },
    typeName () {
      const type = this.submitForm.type
      const target = configTypes.find((item) => item.id == type) || { name: '' }
      return target.name
    }
  },
  data () {
    return {
      configTypes,
      submitForm: {
        qumaAlloId: '',
        alloName: '', // 模板名称
        sampName: '', // 样品名称
        model: '', // 型号规格
        type: '', // 类型
        varieties: '', // 品种
        alloClauList: [
          {
            qumaAlloId: '',
            testItem: '', // 检测项目
            measUnit: '', // 计量单位
            techRequ: '' // 技术要求
          }
        ]
      },
      rowTmpl: {
        qumaAlloId: '',
        testItem: '', // 检测项目
        measUnit: '', // 计量单位
        techRequ: '' // 技术要求
      },
      rules: {
        alloName: { required: true, trigger: 'change', message: '请输入' },
        sampName: { required: true, trigger: 'change', message: '请输入' },
        model: { required: true, trigger: 'change', message: '请输入' },
        type: { required: true, trigger: 'change', message: '请输入' },
        varieties: { required: true, trigger: 'change', message: '请输入' },
        title: { required: true, trigger: 'change', message: '请输入' }
      },
      loading: false
    }
  },
  methods: {
    addRow () {
      const rowTmpl = { ...this.rowTmpl }
      this.submitForm.alloClauList.push(rowTmpl)
    },
    deleteRow (index) {
      this.submitForm.alloClauList.splice(index, 1)
    },
    // 打开弹窗
    async handlerOpen () {
      if (this.editData) {
        this.loading = true
        const res = await qumaAlloDetail({ qumaAlloId: this.editData.qumaAlloId })
        if (res.code == 200) {
          this.loading = false
          Object.assign(this.submitForm, res.data)
        } else {
          this.$message.error(res.msg)
        }
      }
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
      this.$refs.form.resetFields()
    },

    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const submitForm = JSON.parse(JSON.stringify(this.submitForm))
          // 处理一下数据
          if (!submitForm.qumaAlloId) submitForm.qumaAlloId = undefined
          submitForm.alloClauList.forEach((item) => {
            item.qumaAlloId = submitForm.qumaAlloId
          })

          addOrUpdateQumaAllo(this.submitForm).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success('操作成功！')
              this.$emit('refresh')
              this.visibleDialog = false
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
