<template>
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="质检单模板名称:">
        <el-input v-model.trim="searchForm.alloName" placeholder="请输入" clearable ></el-input>
      </el-form-item>
      <el-form-item label="模板类型:">
        <el-select v-model="searchForm.type" clearable>
          <el-option v-for="item in configTypes" :value="item.id" :label="item.name" :key="item.id" />
        </el-select>
      </el-form-item>
    </BaseSearchForm>

    <BaseTable
      id="qualityTestConfig"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <template #buttons>
        <el-button
          v-auth="'material_qualityManage_addOrUpdateQumaAllo_add'"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="editVisible=true"
        >新增</el-button>

        <el-upload
          v-auth="'material_qualityManage_importQumaAllo'"
          style="display:inline-block"
          action="#"
          :auto-upload="false"
          accept=".xlsx,.xls"
          :on-change="handleExcel"
          :show-file-list="false"
        >
          <el-button
            size="small"
            type="primary"
            icon="el-icon-upload2"
            class="ml-10 mr-10"
          >导入</el-button>
        </el-upload>

        <el-button
          size="small"
          v-auth="'material_qualityManage_exportQumaAlloDemo'"
          type="primary"
          icon="el-icon-download"
          @click="exportTmpl"
        >导出模板</el-button>
      </template>
      <vxe-column type="seq" field="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="alloName" title="质检单模板名称"></vxe-column>
      <vxe-column field="typeName" title="模板类型"></vxe-column>
      <vxe-column field="regtime" title="创建时间"></vxe-column>
      <vxe-column field="userName" title="创建人"></vxe-column>
      <vxe-column v-if="hasAuth('qualityTestConfig_tableOperation')" field="operations" title="操作" width="220" :show-overflow="false" >
        <template #default="{row}">
          <el-button
            v-auth="'material_qualityManage_addOrUpdateQumaAllo_edit'"
            type="primary"
            @click="handlerEdit(row)"
          >编辑</el-button>
          <el-button
            v-auth="'material_qualityManage_qumaAlloDetail'"
            type="primary"
            @click="handlerDetail(row)"
          >详情</el-button>
          <el-button
            v-auth="'material_qualityManage_exportQumaAllo'"
            type="primary"
            @click="handlerExport(row)"
          >导出</el-button>
          <el-button
            v-auth="'material_qualityManage_deleteQumaAllo'"
            type="danger"
            @click="handlerDelete(row)"
          >删除</el-button>
        </template>
      </vxe-column>
    </BaseTable>

    <!-- 新增|编辑 -->
    <EditConfig :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList" />

    <!-- 详情 -->
    <EditDetail :visible.sync="detailVisible" :edit-data.sync="editData" />
  </PageContainer>
</template>

<script>
import {
  getQumaAllo,
  deleteQumaAllo,
  exportQumaAllo,
  exportQumaAlloDemo,
  importQumaAllo
} from '@/apis/quality/qualityTestConfig'
import { configTypes } from './util'
import { exportToxlsx } from '@/utils/tools'
import EditConfig from './components/EditConfig'
import EditDetail from './components/EditDetail'
import BaseSearchForm from '../../../../components/common/BaseSearchForm/index.vue'
export default {
  data () {
    return {
      configTypes,
      searchForm: {
        alloName: '',
        type: ''
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 编辑相关
      editData: null,
      editVisible: false,
      detailVisible: false,
      bindVisible: false
    }
  },
  created () {
    this.initList()
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { tablePage } = this
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getQumaAllo(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            const target = configTypes.find(({ id }) => id == item.type) || { name: '' }
            item.typeName = target.name
            return item
          })
          tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlerEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    async handlerExport ({ alloName, qumaAlloId }) {
      const res = await exportQumaAllo({ qumaAlloId })
      exportToxlsx(res, '质检单模板_' + alloName)
    },

    handlerDelete ({ qumaAlloId }) {
      this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteQumaAllo({ qumaAlloId }).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    async exportTmpl () {
      const res = await exportQumaAlloDemo()
      exportToxlsx(res, '质检单配置模板')
    },

    handleExcel (file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      importQumaAllo(formData).then(res => {
        if (res.code == '200') {
          this.$message.success('文件导入成功！')
          this.initList()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  components: {
    EditConfig,
    EditDetail,
    BaseSearchForm
  }
}
</script>
