<template>
  <el-dialog
    v-bind="{title: editData?editData.alloName:'', width:'1024px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <el-table :data="submitForm.alloClauList" border >
      <el-table-column label="样品名称">
        <template v-slot> {{submitForm.sampName}} </template>
      </el-table-column>
      <el-table-column label="型号规格">
        <template v-slot> {{submitForm.model}} </template>
      </el-table-column>
      <el-table-column label="类型">
        <template v-slot> {{typeName}} </template>
      </el-table-column>
      <el-table-column label="品种">
        <template v-slot> {{submitForm.varieties}} </template>
      </el-table-column>
      <el-table-column label="检测项目" prop="testItem"></el-table-column>
      <el-table-column label="单位" prop="measUnit"></el-table-column>
      <el-table-column label="技术要求" prop="techRequ"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {
  qumaAlloDetail
} from '@/apis/quality/qualityTestConfig'
import { configTypes } from '../util'
export default {
  props: {
    visible: { type: Boolean, default: false },
    editData: { type: Object, default: () => {} }
  },
  computed: {
    visibleDialog: {
      set (newValue) {
        this.$emit('update:visible', newValue)
      },
      get () { return this.visible }
    },
    typeName () {
      const type = this.submitForm.type
      const target = configTypes.find((item) => item.id == type) || { name: '' }
      return target.name
    }
  },
  data () {
    return {
      configTypes,
      submitForm: {
        qumaAlloId: '',
        alloName: '', // 模板名称
        sampName: '', // 样品名称
        model: '', // 型号规格
        type: '', // 类型
        varieties: '', // 品种
        alloClauList: [
          {
            qumaAlloId: '',
            testItem: '', // 检测项目
            measUnit: '', // 计量单位
            techRequ: '' // 技术要求
          }
        ]
      }
    }
  },
  methods: {
    // 打开弹窗
    async handlerOpen () {
      if (this.editData) {
        this.loading = true
        const res = await qumaAlloDetail({ qumaAlloId: this.editData.qumaAlloId })
        if (res.code == 200) {
          this.loading = false
          Object.assign(this.submitForm, res.data)
        } else {
          this.$message.error(res.msg)
        }
      }
    },

    // 关闭弹窗
    handlerClose () {
      const sourceForm = this.$options.data(this).submitForm
      this.submitForm = Object.assign({}, this.submitForm, sourceForm)
      this.$emit('update:editData', null)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-table .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}
</style>
